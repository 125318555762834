
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$boardBillCentrix-primary: mat.define-palette(mat.$indigo-palette);
$boardBillCentrix-accent: mat.define-palette(mat.$green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$boardBillCentrix-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$boardBillCentrix-theme: mat.define-light-theme((
  color: (
    primary: $boardBillCentrix-primary,
    accent: $boardBillCentrix-accent,
    warn: $boardBillCentrix-warn,
  )
));
@font-face {
  font-family: 'MontserratAlt-light';
  src: url('assets/font/MontserratAlternates-Light.ttf');
}
@font-face {
  font-family: 'MontserratAlt-regular';
  src: url('assets/font/MontserratAlternates-Regular.ttf');
}
@font-face {
  font-family: 'MontserratAlt-bold';
  src: url('assets/font/MontserratAlternates-Bold.ttf');
}
@font-face {
  font-family: 'MontserratAlt-sbold';
  src: url('assets/font/MontserratAlternates-SemiBold.ttf');
}
@font-face {
  font-family: 'MontserratAlt-medium';
  src: url('assets/font/MontserratAlternates-Medium.ttf');
}
@font-face {
  font-family: 'Montserrat-l';
  src: url('assets/font/Montserrat-Light.ttf');
}
@font-face {
  font-family: 'Montserrat-sbold';
  src: url('assets/font/Montserrat-SemiBold.ttf');
}
@font-face {
  font-family: 'Montserrat-reg';
  src: url('assets/font/Montserrat-Regular.ttf');
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($boardBillCentrix-theme);

@import url('https://fonts.googleapis.com/css?family=Montserrat+Alternates');

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat Alternates', sans-serif;
}
html, body { height: 100%; }
body { margin: 0;  }
h1{
  font-size: 40px;
}
.white-icon{
  color: white!important;
  margin-right: 0!important;
}
.option-portal {
  padding: 0 15px!important;
  background: #5097ff;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white;
}
.menu_report span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
app-confirm{
  padding: 40px;
  display: block;
}

app-confirm button {
  background: #5097ff;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 600;
}
app-confirm button:hover {
  background: #62ffcf;
  border: none;
  padding: 10px 20px;
  color: #5097ff;
}
app-confirm div {
  margin: 30px 0;
}
.bar-preview{
  display: flex;
  align-items: center;
    justify-content: center;
  text-align: center;
  background-color: #60FFCF;
  position:absolute;
  width: 100%;
  left: 0;
  top: 0;
  h3{
    padding: 10px;
    margin:0;
  }
  a{
    background-color: white;
    padding: 5px;
    border-radius: 5px;
  }
  a:hover{
    background-color:#5097ff ;
  }
}
.linked-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  img {
      width: 6rem;
      height: auto;
      
  }
  button{
      background: #5197ff7e;
      border-radius: 0!important;
      color:white;
      font-family: "MontserratAlt-medium";
      font-size: 16px;
      height: 3rem;

  }
  button:first-child {
      border-top-left-radius: 10px!important;
      border-bottom-left-radius: 10px!important;
    }
    
   
  button:last-child {
      border-top-right-radius: 10px!important;
      border-bottom-right-radius: 10px!important;
    }
  .active{
      background: #5197FF;
  }
}
@media (max-width:600px) {
  h1{
    font-size: 30px;
  }
}
.two-column{
  display: flex;
}
.ng-tns-c30-0 {
  
  z-index: 99999!important;
}
.custom-dialog-container .mat-dialog-container {
  background-color: white; 
  position: relative;
  z-index: 10000; 
}
.custom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.161); 
  z-index: 9999; 
}
.custom-dialog-zindex {
  z-index: 99999 !important; 
}
.spinner {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}